import { graphql, useStaticQuery } from "gatsby"
import { PostPreviewsQuery } from "../generated/graphql"

export type PostPreview = {
  body: PostPreviewsQuery
}

interface PostPreviewResult {
  body: string
  excerpt: string
  id: string
  timeToRead: string
  title: string
  slug: string
  date: string
  tags: string[]
}

const usePostPreviews = () => {
  const data: PostPreviewsQuery = useStaticQuery(graphql`
    query PostPreviews {
      allMdx(
        filter: { frontmatter: { published: { eq: true } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          body
          excerpt
          timeToRead
          frontmatter {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            tags
          }
        }
      }
    }
  `)

  return (
    data &&
    data.allMdx &&
    data.allMdx.nodes.map(post => ({
      body: post.body,
      excerpt: post.excerpt,
      id: post.id,
      timeToRead: post.timeToRead,
      title: post.frontmatter && post.frontmatter.title,
      slug: post.frontmatter && post.frontmatter.slug,
      date: post.frontmatter && post.frontmatter.date,
      tags: post.frontmatter && post.frontmatter.tags,
    }))
  )
}

export default usePostPreviews
