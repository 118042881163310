import React, { ReactElement } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import SEO from "../components/SEO"
import usePostPreviews from "../hooks/use-post-previews"

const IndexPage: React.FC = (): ReactElement => {
  const { me } = useStaticQuery(graphql`
    {
      me: file(relativePath: { eq: "me.jpeg" }) {
        sharp: childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const posts = usePostPreviews()

  if (!posts) {
    return <div>No posts found.</div>
  }

  return (
    <>
      <SEO title="Home" />
      <header className="px-8 py-16 bg-gray-200 flex justify-start items-center">
        <Image
          fixed={me.sharp.fixed}
          className="rounded-full shadow-lg border-4 border-gray-1000"
        />
        <section className="ml-4">
          <h1 className="text-4xl tracking-wide font-semibold leading-none">
            Michael Anhari
          </h1>
          <ul className="mt-2">
            <li className="inline-block">
              <a href="https://twitter.com/michaelanhari" className="underline">
                twitter
              </a>
            </li>
            <li className="inline-block ml-3">
              <a href="https://github.com/anhari" className="underline">
                github
              </a>
            </li>
          </ul>
        </section>
      </header>
      <section className="p-12">
        <main className="flex flex-col items-start justify-start">
          <section className="mt-4">
            {posts.length > 0 ? (
              posts.map(
                (post): ReactElement => (
                  <article key={post.id} className="mt-8 first:mt-0">
                    <div className="flex items-center justify-between">
                      <div className="flex-grow">
                        <h2 key={post.id} className="text-3xl">
                          <Link
                            to={`/blog/${post.slug}` || "not-found"}
                            className="text-gray-900 hover:underline max-w-xl inline-block"
                          >
                            {post.title}
                          </Link>
                        </h2>
                        <div className="mt-1 text-sm flex flex-col">
                          <div className="text-gray-800">
                            {post.date}
                            <span className="px-2">&bull;</span>
                            {post.timeToRead} min read
                          </div>
                          <ul className="mt-1 space-x-2">
                            {post.tags &&
                              post.tags.map(
                                (tag, index): ReactElement => (
                                  <li
                                    key={tag || index}
                                    className="text-xs inline-block text-sm leading-none text-gray-600"
                                  >
                                    {tag}
                                  </li>
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                )
              )
            ) : (
              <div className="py-10">Coming soon!</div>
            )}
          </section>
        </main>
      </section>
    </>
  )
}

export default IndexPage
